import React from 'react';

export function AggregatorDisclosure() {
  return (
    <div>
      <h1>911 &amp; E911 Disclosure</h1>
        <p>
          The following sets forth mobile service provider’s (“Provider”, “we”,
          “us”, or “our”) 911 and E911 Disclosure (“Disclosure”) to its customers
          (“you” or “your”).
        </p>
        <p>
          The purpose of this Disclosure is to inform of any differences between
          the 911 and E911 access capabilities available with the Provider’s Wi-Fi
          calling service (the "Wi-Fi 911 Service") as compared to the 911 and E911
          access capability available with traditional wired telephone service.
          It is important that you understand how these differences affect your
          ability to access 911 and E911 services. We ask that you carefully read
          this Disclosure. If you have any questions or concerns about the information
          contained in this notice, or if you do not understand anything discussed in
          this notice, please contact your Provider or Provider's customer support,
          the contact information for which is located on the Provider's website.
        </p>
        <ul>
          <li>
            THE WI-FI 911 SERVICE MAY NOT OPERATE DURING A POWER OUTAGE. You acknowledge
            and understand that the Wi-Fi 911 Service does not function in the event of
            a power failure or disruption. Should there be an interruption in the power
            at the Wi-Fi location, the Provider’s Wi-Fi calling service, including the
            Wi-Fi 911 Service, will not function until power is restored. A power failure
            or disruption may require the customer to reset or reconfigure equipment prior
            to utilizing the Provider’s service, including the Wi-Fi 911 Service. We
            strongly recommend customers have all equipment on long running UPS Systems,
            have a land line also at your premise that can be used, and have a redundant
            or backup Internet Connection. You are responsible for providing an uninterruptible
            backup power supply if you wish to ensure continued operation of electrical equipment
            in the event of a power outage.
          </li>
          <li>
            THE WI-FI 911 SERVICE WILL NOT OPERATE IF YOUR WI-FI CONNECTION IS DISRUPTED. You
            acknowledge and understand that service outages or termination of service by your
            broadband provider and/or ISP or by your Provider will prevent you from using the
            Provider’s Wi-Fi calling service, including the Wi-Fi 911 Service. A service outage
            for any reason other than suspension of service will prevent you from using the
            Provider’s Wi-Fi calling service, including the Wi-Fi 911 Service. Once your Wi-Fi
            connection and/or the Provider’s Wi-Fi calling service has been restored, you may
            be required to reset or reconfigure your equipment before you will be able to use
            the Provider’s Wi-Fi calling service to contact 911 and E911 services.
          </li>
          <li>
            YOU MUST PROVIDE YOUR PROVIDER WITH YOUR CORRECT SERVICE ADDRESS OR CALLS TO 911
            MADE USING THE WI-FI 911 SERVICE MAY BE ROUTED TO EMERGENCY PERSONNEL WHO WILL NOT
            BE ABLE TO ASSIST YOU. When you dial 911 using the Wi-Fi 911 Service, your call is
            routed from your Provider’s supplier’s network to the Public Safety Answering Point (PSAP)
            or local emergency operator designated for the address that you listed at the time
            of activation (your “Registered Address”). You acknowledge and understand that when
            you dial 911 from your Provider’s mobile device, it is intended that you will be
            routed to the general telephone number for the PSAP or local emergency service
            provider (which may not be answered outside business hours in some areas), and may
            not be routed to the 911 dispatcher(s) who are specifically designated to receive
            incoming 911 calls using traditional 911 dialing. Your Provider relies on third
            parties for the forwarding of underlying routing information, and your Provider and
            its third party provider(s) therefore disclaim any and all liability or responsibility
            in the event such information or routing is incorrect. If you notice that the location
            information identified in your contract or bill is inaccurate, you can correct your
            service address by updating your account preferences with your Provider or contact your
            Provider's customer support, the contact information for which is located on the Provider's website.
          </li>
          <li>
            WI-FI 911 SERVICE CALLS MAY NOT COMPLETE OR MAY BE ROUTED TO EMERGENCY PERSONNEL WHO WILL NOT BE ABLE
            TO ASSIST IF YOU DISABLE, DAMAGE OR MOVE THE EQUIPMENT TO A LOCATION OTHER THAN THE SERVICE ADDRESS
            YOU PROVIDED YOUR PROVIDER WHEN SERVICE WAS INITIATED. You acknowledge and understand that 911 and
            E911 does not function if you move your Provider’s mobile device to a different street address, unless
            and until you have notified your Provider of any such change in your Registered Address. You also
            acknowledge that it may take up to 2 hours for any change in address to be processed. Accordingly,
            you should notify your Provider in advance of any and all changes to your Registered Address. Failure
            to provide the current and correct physical address and location of your Provider’s mobile device may
            result in any 911 call you make being routed to the incorrect local emergency service provider and
            emergency personnel being dispatched to the incorrect location. If you wish to move to a new service
            address or report damage to your Provider’s mobile equipment, please update your account preferences
            with your Provider or contact your Provider's customer support, the contact information for which is
            located on the Provider's website.
          </li>
          <li>
            EMERGENCY PERSONNEL MAY NOT BE ABLE TO IDENTIFY YOUR PHONE NUMBER IN ORDER TO CALL YOU BACK. You
            understand and acknowledge that it may not be possible for the Public Safety Answering Point (PSAP)
            and the local emergency personnel to identify your phone number when you dial 911 using the Wi-Fi 911
            Service. The Provider’s system is configured in most instances to send the automated number identification
            information; however, one or more telephone companies route the traffic to the PSAP. The PSAP itself must
            be able to receive the information and pass it along properly, and the PSAPs may not be technically
            capable of doing so on a consistent basis. You acknowledge and understand that PSAP and emergency
            personnel may not be able to identify your phone number in order to call you back if the call cannot
            be completed, is dropped or disconnected, or if you are physically unable to tell them your phone number,
            and/or if your Wi-Fi 911 Service is not operational for any reason other than suspension of service.
          </li>
          <li>
            EMERGENCY PERSONNEL MAY NOT BE ABLE TO IDENTIFY YOUR ADDRESS IF YOU USE YOUR EQUIPMENT AT AN ADDRESS
            OTHER THAN YOUR REGISTERED ADDRESS. Unless and until it is technically feasible to automatically
            transmit the address from which a 911 and E911 call originates, you must be able to state your location
            and the nature of your emergency if you use your Provider’s Wi-Fi calling service to call 911 from any
            address other than your Registered Address as PSAP and emergency personnel will NOT have your address
            information. You acknowledge and understand that PSAP and emergency personnel will not be able to find
            your location if the call cannot be completed, is dropped or disconnected, if you are physically unable
            to tell them your location, or if the service is not operational for any reason other than suspension
            of service.
          </li>
          <li>
            WI-FI 911 SERVICE CALLS MAY BE DELAYED OR DROPPED DUE TO NETWORK ARCHITECTURE. You understand and
            acknowledge that, due to technical constraints, there is a greater possibility of network congestion
            and/or reduced speed in the routing of a 911 call made utilizing your Provider’s Wi-Fi calling service
            as compared to traditional 911 dialing over traditional public telephone networks. You acknowledge and
            understand that a 911 call from your Provider’s mobile equipment will be routed to the general telephone
            number for the local emergency service provider (which may not be answered outside business hours in
            some areas), and will not be routed to the 911 dispatcher(s) who are specifically designated to receive
            incoming 911 calls at such local provider’s facilities where such calls are routed using traditional 911
            dialing. You acknowledge and understand that there may be a greater possibility that the general telephone
            number for the local service provider will produce a busy signal or will take longer to answer, as compared
            to those 911 calls routed to the 911 dispatcher(s) who are specifically designated to receive incoming 911
            calls using traditional 911 dialing.
          </li>
          <li>
            IN ADDITION, YOU SHOULD MAINTAIN ALTERNATE MEANS OF CONTACTING 911 and E911 SERVICES. You should always have
            an alternative means of accessing emergency response services using traditional 911 or other emergency
            notification services.
          </li>
        </ul>
    </div>
  );
}
