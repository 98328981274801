import React from 'react';

export function EVADDisclosure() {
  return (
    <div>
      <h1>911 &amp; E911 Disclosure</h1>
        <p>
          PLEASE READ THIS DISCLOSURE CAREFULLY
        </p>
        <p>
          The following sets forth the mobile service provider’s (“Provider”, “we”,
          “us”, “our”) 911 and E911 Disclosure (“Disclosure”) to its customers
          (“you” or “your”) when using voice over Wi-Fi.
        </p>
        <p>
          The purpose of this Disclosure is to inform you of the limited 911 and
          E911 access capabilities available when using “Wi-Fi Calling,” the ability
          to place and receive calls and messages over a Wi-Fi network connection.
          Wi-Fi Calling is a feature available on only certain capable devices when
          connected to a functioning Wi-Fi network.  For example, availability of
          Wi-Fi Calling may vary depending on the device manufacturer’s settings.
          You must set up Wi-Fi Calling through Provider.
        </p>
        <p>
          YOU MUST PROVIDE PROVIDER WITH THE MOST RECENT INFORMATION IDENTIFYING YOUR
          PHYSICAL LOCATION (YOUR “REGISTERED LOCATION”) WHEN SETTING UP WI-FI CALLING.
          THIS REGISTERED LOCATION CANNOT BE A P.O. BOX.  IF YOU FAIL TO PROVIDE AND
          UPDATE YOUR REGISTERED LOCATION, EMERGENCY PERSONNEL MAY NOT BE ABLE TO ASSIST
          YOU.  FAILURE TO PROVIDE OR UPDATE YOUR REGISTERED LOCATION MAY PREVENT EMERGENCY
          SERVICES FROM BEING PROPERLY ROUTED TO YOUR PHYSICAL LOCATION.
        </p>
        <p>
          When you dial 911 using the Wi-Fi 911 Service from your phone, handset, device,
          SIM card, data card, or other equipment or third party device used with Wi-Fi
          Calling (“Device”), your call is routed from Provider’s supplier’s network to
          the Public Safety Answering Point (PSAP) or local emergency operator designated
          for the address that you listed at the time you set up Wi-Fi calling on your
          Device. You acknowledge and understand that when you dial 911 from Device while
          utilizing the Wi-Fi 911 service, it is intended that you will be routed to the
          general telephone number for the PSAP or local emergency service provider (which
          may not be answered outside business hours in some areas), and may not be routed
          to the 911 dispatcher(s) who are specifically designated to receive incoming 911
          calls using traditional 911 dialing. You acknowledge and understand that there
          may be a greater possibility that the general telephone number for the local
          service provider will be dropped, will produce a busy signal and/or will take
          longer to answer, as compared to those 911 calls routed to the 911 dispatcher(s)
          who are specifically designated to receive incoming 911 calls using traditional
          911 dialing. Provider relies on third parties for the forwarding of underlying
          routing information, and Provider and its third party provider(s) therefore disclaim
          any and all liability or responsibility in the event such information or routing
          is incorrect. You may update your Registered Location at any time through your
          Device’s Wi-Fi calling preferences. If you need additional assistance, please
          contact your Provider or Provider’s customer support.
        </p>
        <p>
          PROVIDER’S WI-FI 911 SERVICE WILL NOT OPERATE IF YOUR WI-FI CONNECTION IS DISRUPTED.
          You acknowledge and understand that service outages or termination of service by
          your broadband provider and/or ISP or by Provider will prevent you from using
          Provider’s Wi-Fi calling service, including the Wi-Fi 911 Service. A service outage
          for any reason other than suspension of service will prevent you from using Provider’s
          Wi-Fi calling service, including the Wi-Fi 911 Service. Once your Wi-Fi connection
          and/or Wi-Fi calling service has been restored, you may be required to reset or
          reconfigure your Device and all other equipment before you will be able to use Provider’s
          Wi-Fi calling service to contact 911 and E911 services.
        </p>
        <p>
          EMERGENCY PERSONNEL MAY NOT BE ABLE TO IDENTIFY YOUR PHONE NUMBER IN ORDER TO CALL
          YOU BACK WHEN USING THE WI-FI 911 SERVICE. You understand and acknowledge that it
          may not be possible for the Public Safety Answering Point (PSAP) and the local
          emergency personnel to identify your phone number when you dial 911 using the Wi-Fi
          911 Service. Provider’s system is configured in most instances to send the automated
          number identification information; however, one or more telephone companies route the
          traffic to the PSAP. The PSAP itself must be able to receive the information and pass
          it along properly, and the PSAPs may not be technically capable of doing so on a
          consistent basis. You acknowledge and understand that PSAP and emergency personnel
          may not be able to identify your phone number in order to call you back if the call
          cannot be completed, is dropped or disconnected, or if you are physically unable to
          tell them your phone number, and/or if your Wi-Fi 911 Service is not operational for
          any reason other than suspension of service.
        </p>
        <p>
          WI-FI 911 SERVICE CALLS MAY BE DELAYED OR DROPPED DUE TO NETWORK ARCHITECTURE. You
          understand and acknowledge that, due to technical constraints, there is a greater
          possibility of network congestion, busy signals, dropped calls, delayed connection,
          and/or reduced speed in the routing of a 911 call made utilizing the E 911 Service as
          compared to traditional 911 dialing over traditional public telephone networks. 
        </p>
        <p>
          IN ADDITION, YOU SHOULD MAINTAIN ALTERNATE MEANS OF CONTACTING 911 AND E911 SERVICES,
          SUCH AS BY USING A TRADITIONAL PHONE (WIRED OR WIRELESS) WITHOUT WI-FI CALLING. You
          acknowledge that Provider does not offer primary line or lifeline services. You should
          always have an alternative means of accessing emergency response services using
          traditional 911 or other emergency notification services.
        </p>
    </div>
  );
}
