import React, { PureComponent } from "react";
import "./App.css";
import Address from "../containers/Address";
import Complete from "./Complete";
import Logo from "./Logo";
import Spinner from "./Spinner";

class App extends PureComponent {
  renderContent() {
    const {
      loaded,
      updated,
      updating,
      brand,
      eligibleForFreeSim,
      gbaSim
    } = this.props;
    if (!loaded) {
      return <Spinner />;
    }
    if (updated) {
      return <Complete />;
    }

    if (updating) {
      return (
        <div>
          <Spinner />
          <p>
            Please allow several minutes for us to configure Wi-Fi Calling on
            your account...
          </p>
        </div>
      );
    }

    if (gbaSim) {
      return (
        <div>
          <h2>Emergency 911 Address</h2>
          <Address />
        </div>
      );
    }

    let link =
      brand === "ultra" ? (
        <a href="https://my.ultramobile.com">my.ultramobile.com</a>
      ) : (
        <a href="https://my.mintmobile.com">my.mintmobile.com</a>
      );

    if (!eligibleForFreeSim) {
      return (
        <div>
          <p>
            <br />
            Sorry, your SIM card is incompatible with Wi-Fi Calling. Our records
            show that you have requested a free replacement SIM card via {link}.
            Upon receiving that SIM card in the mail, please activate it via{" "}
            {link}. Once the SIM is active, insert it into your device and
            return to this page.
          </p>
        </div>
      );
    }

    return (
      <div>
        <p>
          <br />
          Sorry, your SIM card is incompatible with Wi-Fi Calling. Please log in
          to account management via our website at {link}. Proceed to the WiFi
          Talk and Text section, where you can order your free replacement SIM
          card.
        </p>
      </div>
    );
  }
  render() {
    const { brand } = this.props;
    const brandName = brand ? brand.name : undefined;
    return (
      <div className={"App App--" + brandName}>
        <div>
          <h1>
            <Logo brandName={brandName} />
            Wi-Fi Calling
          </h1>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default App;
