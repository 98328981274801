import React, { PureComponent } from "react";
import Spinner from "./Spinner";
import AddressForm from "./AddressForm";
import Agreement from "./Agreement";

class Address extends PureComponent {
  state = { showDisclosure: false };
  submit = values => {
    this.props.updateWiFi(values);
  };
  showDisclosure = (show = true) => {
    this.setState({ showDisclosure: show });
  };
  render() {
    const { user, wifi } = this.props;
    const { showDisclosure } = this.state;
    return (
      <div>
        {showDisclosure && (
          <Agreement brand={user.brand} handleShowDisclosure={this.showDisclosure} />
        )}
        <div>
          <p>
            In order to activate Wi-Fi Calling on your account, it is required
            that we collect your primary address for use in the event of an
            emergency 911 call.
          </p>
          {(user.loading || wifi.loading || wifi.updating) && <Spinner />}
          {wifi.error && (
            <p className="form-error">
              We’re sorry, either the address you entered is invalid or there was an error on our side.
              Please update your address or try again later.
            </p>
          )}
          {wifi.loaded &&
            !wifi.updating && (
              <AddressForm
                onSubmit={this.submit}
                handleShowDisclosure={this.showDisclosure}
              />
            )}
        </div>
      </div>
    );
  }
}

export default Address;
