import { connect } from "react-redux";
import Address from "../components/Address";
import { WI_FI_UPDATE_REQUESTED } from "../store";

export default connect(
  ({ user, wifi }) => {
    return {
      user,
      wifi
    };
  },
  dispatch => {
    return {
      updateWiFi: address =>
        dispatch({
          type: WI_FI_UPDATE_REQUESTED,
          address
        })
    };
  }
)(Address);
