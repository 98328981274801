import React from "react";
import { reduxForm, Field } from "redux-form";

const VALID_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "VI",
  "WA",
  "WV",
  "WI",
  "WY"
];

function myField({
  input,
  label,
  type,
  pattern,
  meta: { active, touched, error, warning }
}) {
  const showError = touched && error;
  const isCheckbox = type === "checkbox";
  return (
    <div className={"field" + (showError ? " error" : "")}>
      <label className={active && !isCheckbox ? "active" : ""}>
        {isCheckbox && <input {...input} type={type} />}
        {label}
      </label>
      {!isCheckbox && <input {...input} type={type} pattern={pattern} />}
      {touched &&
        ((error && <div className="validation">{error}</div>) ||
          (warning && <div className="warning">{warning}</div>))}
    </div>
  );
}

const upper = value => value && value.toUpperCase();
const zipOnly = value => value.replace(/[^0-9]/g, "");
function required(msg) {
  return function(value) {
    return value ? undefined : msg;
  };
}
const address1Required = required("Please enter your Address 1.");
const cityRequired = required("Please enter your City.");
const stateRequired = required("Please enter your State.");
const zipRequired = required("Please enter your ZIP Code.");
const agreeRequired = required("Please acknowledge.");
const fiveLength = value =>
  value.length === 5 ? undefined : "Please enter your 5 digit postal code.";
const validState = value =>
  VALID_STATES.indexOf(value) !== -1
    ? undefined
    : "Please enter a valid state.";
const validUSAddressValue = value =>
 !value || /^[a-z0-9\s\-.,'#]+$/i.test(value)
 ? undefined
    : "Please enter a valid US address value.";

export default reduxForm({
  form: "e911"
})(({ handleSubmit, handleShowDisclosure }) => (
  <form onSubmit={handleSubmit}>
    <Field
      label="Address 1"
      name="address1"
      validate={[address1Required, validUSAddressValue]}
      component={myField}
      type="text"
    />
    <Field
      label="Address 2"
      name="address2"
      validate={[validUSAddressValue]}
      component={myField}
      type="text"
    />
    <Field
      label="City"
      name="city"
      component={myField}
      validate={[cityRequired, validUSAddressValue]}
      type="text"
    />
    <Field
      label="State"
      name="state"
      normalize={upper}
      validate={[stateRequired, validState, validUSAddressValue]}
      component={myField}
      type="text"
    />
    <Field
      label="ZIP Code"
      name="zip"
      component={myField}
      type="number"
      pattern="[0-9]*"
      normalize={zipOnly}
      validate={[zipRequired, fiveLength, validUSAddressValue]}
    />
    <p>
      <a onClick={() => handleShowDisclosure()}>
        Full Emergency 911 Disclosure
      </a>
    </p>
    <Field
      label="&nbsp;I acknowledge that the above is my primary address and I am aware of the limitations of using Wi-Fi Calling for 911 service"
      name="agree"
      component={myField}
      type="checkbox"
      validate={[agreeRequired]}
    />
    <button>Submit</button>
  </form>
));
