import { combineReducers } from "redux";
import { put, select, takeEvery } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";
import { initialize } from "redux-form";
import { reducer as formReducer } from "redux-form";

export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const WI_FI_QUERY_REQUESTED = "WI_FI_QUERY_REQUESTED";
export const WI_FI_QUERY_SUCCESS = "WI_FI_QUERY_SUCCESS";
export const WI_FI_QUERY_FAILURE = "WI_FI_QUERY_FAILURE";

export const WI_FI_UPDATE_REQUESTED = "WI_FI_UPDATE_REQUESTED";
export const WI_FI_UPDATE_SUCCESS = "WI_FI_UPDATE_SUCCESS";
export const WI_FI_UPDATE_FAILURE = "WI_FI_UPDATE_FAILURE";

function wifi(
  reduxState = {},
  {
    eligibleForFreeSim,
    gbaSim,
    type,
    state,
    city,
    zip,
    address2,
    address1,
    inProgress,
    enabled
  }
) {
  switch (type) {
    case WI_FI_UPDATE_REQUESTED:
      return Object.assign({}, reduxState, { error: false, updating: true });
    case WI_FI_UPDATE_SUCCESS:
      return Object.assign({}, reduxState, {
        error: false,
        updating: false,
        updated: true
      });
    case WI_FI_UPDATE_FAILURE:
      return Object.assign({}, reduxState, {
        error: true,
        updating: false,
        updated: false
      });
    case WI_FI_QUERY_REQUESTED:
      return Object.assign({}, reduxState, { loading: true, loaded: false });
    case WI_FI_QUERY_SUCCESS:
      return Object.assign({}, reduxState, {
        loading: false,
        loaded: true,
        updating: inProgress,
        eligibleForFreeSim,
        gbaSim,
        address1,
        address2,
        city,
        state,
        zip
      });
    case WI_FI_QUERY_FAILURE:
      return Object.assign({}, reduxState, {
        loading: false,
        loaded: false
      });
    default:
      return reduxState;
  }
}

function user(
  state = { token: "", loading: false, loaded: false, error: false },
  { token, brand, type }
) {
  switch (type) {
    case LOGIN_REQUESTED:
      return { token: state.token, loading: true, loaded: false, error: false };
    case LOGIN_FAILURE:
      return { token: state.token, loading: false, loaded: false, error: true };
    case LOGIN_SUCCESS:
      return { brand, token, loading: false, loaded: true, error: false };
    default:
      return state;
  }
}

function* api(method, url, data) {
  const token = yield select(({ user: { token } }) => token);
  return yield axios({
    method,
    url,
    data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

function* loginSaga() {
  const {
    data: { token, brand }
  } = yield api("POST", `${process.env.PUBLIC_URL}/apple/login`, {});
  yield put({ type: LOGIN_SUCCESS, token, brand });
  yield put({ type: WI_FI_QUERY_REQUESTED });
}

function* queryWifiSaga() {
  const {
    data: {
      eligibleForFreeSim,
      gbaSim,
      state,
      city,
      zip,
      address2,
      address1,
      inProgress
    }
  } = yield api("GET", `${process.env.PUBLIC_URL}/apple/wifi`);

  yield put({
    type: WI_FI_QUERY_SUCCESS,
    eligibleForFreeSim,
    gbaSim,
    address1,
    address2,
    city,
    state,
    zip,
    inProgress
  });
  yield put(
    initialize("e911", {
      address1,
      address2,
      city,
      state,
      zip
    })
  );
}

function* updateWifiSaga({
  address: { state, city, zip, address2, address1 }
}) {
  try {
    yield api("POST", `${process.env.PUBLIC_URL}/apple/wifi`, {
      address1,
      address2: address2 || " ",
      city,
      state,
      zip
    });
  } catch (e) {
    yield put({ type: WI_FI_UPDATE_FAILURE });
    return;
  }

  yield delay(5000);

  for (let retry = 0; retry < 30; retry++) {
    let {
      data: { state, city, zip, address2, address1, inProgress, enabled }
    } = yield api("GET", `${process.env.PUBLIC_URL}/apple/wifi`);
    if (!inProgress && enabled) {
      yield delay(10000);
      yield put({
        type: WI_FI_UPDATE_SUCCESS,
        address1,
        address2,
        city,
        state,
        zip
      });
      return;
    }
    yield delay(500 + retry * retry * 100);
  }

  yield put({ type: WI_FI_UPDATE_FAILURE });
}

export function* mySaga() {
  yield takeEvery(LOGIN_REQUESTED, loginSaga);
  yield takeEvery(WI_FI_QUERY_REQUESTED, queryWifiSaga);
  yield takeEvery(WI_FI_UPDATE_REQUESTED, updateWifiSaga);
  yield takeEvery(WI_FI_UPDATE_FAILURE, function*() {
    yield put({ type: WI_FI_QUERY_REQUESTED });
  });
  yield put({ type: LOGIN_REQUESTED });
}

export const reducers = combineReducers({
  form: formReducer,
  wifi,
  user
});
