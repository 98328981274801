import React from "react";
import "./Spinner.css";

export default function Spinner() {
  return (
    <div className="Spinner">
      <div className="Spinner--bounce1" />
      <div className="Spinner--bounce2" />
      <div className="Spinner--bounce3" />
    </div>
  );
}
