import React from "react";

export default function Complete() {
  return (
    <div>
      <h2>Wi-Fi Calling has been successfully setup on your account.</h2>
      <button
        onClick={() => {
          if (typeof window.WiFiCallingWebViewController === "object") {
            window.WiFiCallingWebViewController.phoneServicesAccountStatusChanged();
          }
        }}
      >
        Close
      </button>
    </div>
  );
}
