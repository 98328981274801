import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware } from "redux";
import { reducers, mySaga } from "./store";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const store = function (initialState) {
  return createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
};

document.addEventListener("DOMContentLoaded", function (event) {
  const match = window.location.search.match(/^\?t=(.*)$/);
  if (process.env.NODE_ENV !== "production") {
    if (!match) {
      fetch("/dummy")
        .then((x) => x.text())
        .then((html) => {
          document.getElementById("root").innerHTML = html;
        });
      return;
    }
  }

  ReactDOM.render(
    <Provider store={store({ user: { token: match[1] } })}>
      <App />
    </Provider>,
    document.getElementById("root")
  );

  sagaMiddleware.run(mySaga);
});
