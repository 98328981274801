import { connect } from "react-redux";
import App from "../components/App";

export default connect(
  ({
    user: { brand },
    wifi: { loaded, updating, updated, eligibleForFreeSim, gbaSim }
  }) => {
    return {
      loaded,
      brand,
      updating,
      eligibleForFreeSim,
      gbaSim,
      updated
    };
  }
)(App);
