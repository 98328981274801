import React, { PureComponent } from "react";
import { MintDisclosure } from "./legal/MintDisclosure"
import { UltraDisclosure } from "./legal/UltraDisclosure"
import { AggregatorDisclosure } from "./legal/AggregatorDisclosure"
import { EarthlinkDisclosure } from "./legal/EarthlinkDisclosure"
import { EVADDisclosure } from "./legal/EVADDisclosure";

export default class Agreement extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { handleShowDisclosure, brand } = this.props;

    let disclosure = <AggregatorDisclosure />
    if (brand.brandType === 'EVAD'){
      disclosure = <EVADDisclosure />
    } else if (['ultra', 'univision'].includes(brand.name)) {
      disclosure = <UltraDisclosure />
    } else if (['mint'].includes(brand.name)) {
      disclosure = <MintDisclosure />
    } else if (brand.name === 'earthlink') {
      disclosure = <EarthlinkDisclosure />
    }

    return (
      <div className="agreement">
        <div className="bar">
          <svg
            onClick={() => handleShowDisclosure(false)}
            width="22"
            height="22"
            viewport="0 0 22 22"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="21"
              x2="21"
              y2="1"
              stroke="black"
              strokeWidth="2"
            />
            <line
              x1="1"
              y1="1"
              x2="21"
              y2="21"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </div>
        { disclosure }
      </div>
    );
  }
}
